<template>
  <div class="noticethree">
     <top-bar :title="'三务公开'" :left="true"></top-bar>
    <div class="workGoal" v-for="item in dataList" :key="item.value">
      <work-item
        v-show="item.label == '村务公开'"
        :title="item.label"
        titlEng="Open village affairs"
        :imgurl="require('@/assets/img/openVillage.png')"
        style="
          background-image: linear-gradient(to right, #9487fd, #7a68f2);
          border-radius: 10px;
        "
        @click.native="workTargetList(item.value, item.label)"
      />
      <work-item
        v-show="item.label == '党务公开'"
        :title="item.label"
        titlEng="Open Party affairs"
        :imgurl="require('@/assets/img/openParty.png')"
        style="
          background-image: linear-gradient(to right, #fd7b79, #f64744);
          border-radius: 10px;
        "
        @click.native="workTargetList(item.value, item.label)"
      />
      <work-item
        v-show="item.label == '财务公开'"
        :title="item.label"
        titlEng="Financial disclosure"
        :imgurl="require('@/assets/img/financial.png')"
        style="
          background-image: linear-gradient(to right, #6fa4ff, #367ef4);
          border-radius: 10px;
        "
        @click.native="workTargetList(item.value, item.label)"
      />
      <work-item
        v-show="item.label == '政策宣传'"
        :title="item.label"
        titlEng="Policy advocacy"
        :imgurl="require('@/assets/img/policy.png')"
        style="
          background-image: linear-gradient(to right, #fdb682, #fd8730);
          border-radius: 10px;
        "
        @click.native="workTargetList(item.value, item.label)"
      />
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import workItem from "../workGoals/workItem.vue";

export default {
  components: {
    topBar,
    workItem,
  },
  data() {
    return {
      dataList: {},
    };
  },
  methods: {
    getDataList(code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/dict/listDictByCode"),
        method: "post",
        params: this.$http.adornParams({ code }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.dataList = data.dicts;
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    workTargetList(type, title) {
      this.$router.push({
        name: "gridOpenVillage",
        query: { title: title, type: type },
      });
    },
  },
  mounted() {
    this.getDataList("jqnoticeType");
  },
};
</script>

<style scoped>
.noticethree {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #f6f5f3;
}
.workGoal {
  width: 100%;
  max-height: 17%;
  background-color: #f6f5f3;
  /* margin-top: 150px; */
}
</style>
