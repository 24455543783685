<template>
  <div class="goalsItem">
    <div class="leftArea">
      <div style="color: white; font-size: 19px; font-weight: 600">
        {{ title }}
      </div>
      <div
        style="
          color: rgba(220, 220, 220, 0.4);
          font-size: 15px;
          font-weight: lighter !important;
          margin-top: 1vh;
        "
      >
        <span>{{ titlEng }}</span>
      </div>
      <div class="line"></div>
    </div>
    <div class="rightImg">
      <img style="width: 80px; height: 100px" :src="imgurl" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titlEng: {
      type: String,
      default: " ",
    },
    title: {
      type: String,
      default: " ",
    },
    imgurl: {
      type: String,
      default: require("@/assets/img/recent.png"),
    },
  },
  data() {
    return {};
  },
};
</script>

<style scope>
.goalsItem {
  width: 85%;
  height: 17vh;
  margin: 50px auto;
  position: relative;
}
.leftArea {
  position: absolute;
  left: 5vw;
  top: 3vh;
}
.line {
  background-color: white;
  height: 8px;
  width: 55px;
  border-radius: 19%;
  margin-top: 4vh;
}
.rightImg {
  position: absolute;
  right: 2vw;
  bottom: 5px;
}
</style>
